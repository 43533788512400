import React, { useCallback, useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Tooltip,
} from '@mui/material';
import { useGlobalFilters } from '../hooks/useGlobalFilters';
import {
    DATALINE_GROUPS,
    groupCategory,
    groupEmissionSource,
    groupSubcategory,
} from '../helpers/groups';
import AutocompleteSelect from '../components/atom/AutoCompleteSelect';
import {
    categoryAutocomplete,
    scopeAutocomplete,
    sourceAutocomplete,
    subcategoryAutocomplete,
} from '../autocomplete';
import AddDatalineForm from './components/AddDatalineForm';
import useNotify from '../hooks/useNotify';
import { doSwaggerCall } from '../hooks/useApi';
import convertDatalineTags from '../pages/datalineedit/helpers/convertDatalineTags';
import CommonButton from '../components/atom/CommonButton';

const AddDatalineWizardModal = ({ title, showModal, MODAL_TYPE, setGroupby, setGroupOpen }) => {
    const closeAction = () => showModal(MODAL_TYPE.NONE);
    const { notifySuccess, notifyError } = useNotify();
    const { setSourceFilters, year } = useGlobalFilters();
    const [filterStep, setFilterStep] = useState(true);
    const [percentages, setPercentages] = useState([]);
    const [scope, setScope] = useState(null);
    const [scopeName, setScopeName] = useState(null);
    const [category, setCategory] = useState(null);
    const [categoryName, setCategoryName] = useState(null);
    const [subcategory, setSubcategory] = useState(null);
    const [subcategoryName, setSubcategoryName] = useState(null);
    const [emissionSource, setEmissionSource] = useState(null);
    const [emissionSourceName, setEmissionSourceName] = useState(null);
    const setLocalSourceFilters = useCallback(
        ({
            scopeFilter,
            scopeNameFilter,
            categoryFilter,
            categoryNameFilter,
            subcategoryFilter,
            subcategoryNameFilter,
            emissionSourceFilter,
            emissionSourceNameFilter,
        }) => {
            if (typeof scopeFilter !== 'undefined') {
                setScope(scopeFilter);
                setScopeName(scopeNameFilter);
            }
            if (typeof categoryFilter !== 'undefined') {
                setCategory(categoryFilter);
                setCategoryName(categoryNameFilter);
            }
            if (typeof subcategoryFilter !== 'undefined') {
                setSubcategory(subcategoryFilter);
                setSubcategoryName(subcategoryNameFilter);
            }
            if (typeof emissionSourceFilter !== 'undefined') {
                setEmissionSource(emissionSourceFilter);
                setEmissionSourceName(emissionSourceNameFilter);
            }
        },
        [setScope, setCategory, setSubcategory, setEmissionSource]
    );

    const addDataline = async (values, jump) => {
        const req = doSwaggerCall(
            'Datalines',
            'addDataline',
            { dryRun: false },
            {
                year: parseInt(values.year, 10),
                emissionSourceId: values.emission_source_id,
                emissionDescription: values.emission_description,
                calculationType: values.calculation_type,
                siteId: values.site_id,
                fields: values.fields,
                amount: parseFloat(values.amount),
                notes: values.notes,
                sop: values.sop,
                isEstimated: values.is_estimated,
                assignedToUserId: values.assigned_user?.user_id,
                ownerUserId: values.owner_user?.user_id,
                documentIdList: values.documents?.map((e) => e.id),
                datalineTagList: convertDatalineTags(values.datalineTags, percentages),
                fromDate: values.from_date,
                toDate: values.to_date,
                cost: parseFloat(values.cost),
            }
        );
        req.then(() => {
            if (jump) {
                // set global filter
                setSourceFilters({
                    scopeFilter: scope,
                    scopeNameFilter: scopeName,
                    categoryFilter: category,
                    categoryNameFilter: categoryName,
                    subcategoryFilter: subcategory,
                    subcategoryNameFilter: subcategoryName,
                    emissionSourceFilter: emissionSource,
                    emissionSourceNameFilter: emissionSourceName,
                });
            }
            // remove this source from groupOpen, so reload problem is gone
            setGroupOpen((prev) => prev.filter((e) => e !== `${values.emission_source_id}-source`));
            setGroupby(DATALINE_GROUPS.CATEGORY);
            showModal(MODAL_TYPE.NONE);
            notifySuccess('Dataline added');
        }).catch((err) => {
            notifyError(err);
        });
    };

    const handleClear = () => {
        setLocalSourceFilters({
            scopeFilter: null,
            categoryFilter: null,
            subcategoryFilter: null,
            emissionSourceFilter: null,
        });
    };

    return (
        <Dialog open onClose={closeAction} maxWidth="md" fullWidth>
            {filterStep && <DialogTitle sx={{ alignSelf: 'center' }}>{title}</DialogTitle>}
            <DialogContent>
                {filterStep ? (
                    <Grid container spacing={2} sx={{ pt: 1 }}>
                        <Grid item xs={12}>
                            <AutocompleteSelect
                                initCall={scopeAutocomplete.init}
                                autocompleteCall={scopeAutocomplete.search}
                                value={scope}
                                setValue={(ev, getItem) => {
                                    const item = getItem(ev);
                                    setLocalSourceFilters({
                                        scopeFilter: item?.id || null,
                                        scopeNameFilter: item?.name || null,
                                        categoryFilter: null,
                                        categoryNameFilter: null,
                                        subcategoryFilter: null,
                                        emissionSourceFilter: null,
                                    });
                                }}
                            >
                                <TextField label="Scope" fullWidth size="small" />
                            </AutocompleteSelect>
                        </Grid>
                        <Grid item xs={12}>
                            <AutocompleteSelect
                                grouping={groupCategory}
                                initCall={categoryAutocomplete.init}
                                autocompleteCall={categoryAutocomplete.search}
                                value={category}
                                setValue={(ev, getItem) => {
                                    const item = getItem(ev);
                                    setLocalSourceFilters({
                                        scopeFilter: item?.scope_id,
                                        scopeNameFilter: item?.scope_name || null,
                                        categoryFilter: item?.id || null,
                                        categoryNameFilter: item?.name || null,
                                        subcategoryFilter: null,
                                        emissionSourceFilter: null,
                                    });
                                }}
                                searchParams={{
                                    scope,
                                    sourceLimitation: true,
                                }}
                            >
                                <TextField label="Category" fullWidth size="small" />
                            </AutocompleteSelect>
                        </Grid>
                        <Grid item xs={12}>
                            <AutocompleteSelect
                                grouping={groupSubcategory}
                                initCall={subcategoryAutocomplete.init}
                                autocompleteCall={subcategoryAutocomplete.search}
                                value={subcategory}
                                setValue={(ev, getItem) => {
                                    const item = getItem(ev);
                                    setLocalSourceFilters({
                                        scopeFilter: item?.scope_id,
                                        scopeNameFilter: item?.scope_name || null,
                                        categoryFilter: item?.category_id,
                                        categoryNameFilter: item?.category_name || null,
                                        subcategoryFilter: item?.id || null,
                                        subcategoryNameFilter: item?.name || null,
                                        emissionSourceFilter: null,
                                    });
                                }}
                                searchParams={{
                                    scope,
                                    category,
                                    sourceLimitation: true,
                                }}
                            >
                                <TextField label="Subcategory" fullWidth size="small" />
                            </AutocompleteSelect>
                        </Grid>
                        <Grid item xs={12}>
                            <AutocompleteSelect
                                grouping={groupEmissionSource}
                                initCall={sourceAutocomplete.init}
                                autocompleteCall={sourceAutocomplete.search}
                                value={emissionSource}
                                setValue={(ev, getItem) => {
                                    const item = getItem(ev);
                                    setLocalSourceFilters({
                                        scopeFilter: item?.scope_id,
                                        scopeNameFilter: item?.scope_name || null,
                                        categoryFilter: item?.category_id,
                                        categoryNameFilter: item?.category_name || null,
                                        subcategoryFilter: item?.subcategory_id,
                                        subcategoryNameFilter: item?.subcategory_name || null,
                                        emissionSourceFilter: item?.id || null,
                                        emissionSourceNameFilter: item?.name || null,
                                    });
                                }}
                                searchParams={{
                                    scope,
                                    category,
                                    subcategory,
                                    year,
                                    sourceLimitation: true,
                                }}
                            >
                                <TextField label="Source" fullWidth size="small" />
                            </AutocompleteSelect>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container spacing={2} sx={{ pt: 1 }}>
                        <AddDatalineForm
                            year={year}
                            emissionSourceId={emissionSource}
                            addDataline={addDataline}
                            percentages={percentages}
                            setPercentages={setPercentages}
                            setFilterStep={setFilterStep}
                            scopeName={scopeName}
                            categoryName={categoryName}
                            subcategoryName={subcategoryName}
                            emissionSourceName={emissionSourceName}
                        />
                    </Grid>
                )}
            </DialogContent>
            {filterStep && (
                <DialogActions>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <CommonButton
                                onClick={handleClear}
                                fullWidth
                                label="Clear"
                                buttonType="secondary"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            {!emissionSource || emissionSource === '' || emissionSource === null ? (
                                <Tooltip title="Select a source first!">
                                    <span>
                                        <CommonButton
                                            onClick={() => {}}
                                            disabled
                                            fullWidth
                                            label="Enter Data"
                                            buttonType="primary"
                                        />
                                    </span>
                                </Tooltip>
                            ) : (
                                <CommonButton
                                    onClick={() => {
                                        setFilterStep(false);
                                    }}
                                    fullWidth
                                    label="Enter Data"
                                    buttonType="primary"
                                />
                            )}
                        </Grid>
                    </Grid>
                </DialogActions>
            )}
        </Dialog>
    );
};

export default AddDatalineWizardModal;
