import {
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
} from '@mui/material';
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SkeletonTable from '../../../components/atom/SkeletonTable';
import { MODAL_TYPE, useModals } from '../../../hooks/useModal';
import { doSwaggerCall } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';
import SortableTableCell from '../../../components/atom/SortableTableCell';
import { TABLE_ROW_COUNT_OPTIONS } from '../../../helpers/constans';
import { handleChangeRowsPerPage, handlePageChange } from '../../../helpers/handleTableFunctions';
import { useEsgFilters } from '../../../hooks/useEsgFilters';

const EsgQuestionsTable = ({
    rows,
    limit,
    page,
    setParams,
    total,
    loading,
    reloadData,
    sort,
    sortDir,
    setSorting,
}) => {
    const { showModal } = useModals();
    const { notifyError, notifySuccess } = useNotify();
    const { setEsgFilters } = useEsgFilters();

    const deleteEsgQuestion = useCallback((id) => {
        doSwaggerCall('EsgQuestions', 'deleteEsgQuestion', { id })
            .then(() => {
                notifySuccess('ESG question deleted');
                setEsgFilters({
                    esgCategoryFilter: null,
                    esgSubcategoryFilter: null,
                    esgDisclosureFilter: null,
                    esgQuestionFilter: null,
                });
                reloadData();
            })
            .catch((err) => {
                notifyError(err);
            });
    }, []);

    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        <SortableTableCell
                            arrow={sort === 'disclosure' ? sortDir : ''}
                            onClick={() => setSorting('disclosure')}
                        >
                            Disclosure
                        </SortableTableCell>
                        <SortableTableCell
                            arrow={sort === 'name' ? sortDir : ''}
                            onClick={() => setSorting('name')}
                        >
                            Name
                        </SortableTableCell>
                        <TableCell>Fields</TableCell>
                        <SortableTableCell
                            arrow={sort === 'guide_note' ? sortDir : ''}
                            onClick={() => setSorting('guide_note')}
                        >
                            Guide note
                        </SortableTableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ? (
                        <SkeletonTable rows={limit} cells={5} />
                    ) : (
                        rows.map((row) => (
                            <TableRow key={row.id}>
                                <Tooltip title={`${row.esg_hierarchy}`}>
                                    <TableCell>{row.esg_disclosure_name}</TableCell>
                                </Tooltip>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>
                                    {row.fields.length > 0
                                        ? row.fields.map((field) => <p>{field.label}</p>)
                                        : `-`}
                                </TableCell>
                                <Tooltip title={row.guide_note} placement="left">
                                    <TableCell
                                        sx={{
                                            maxWidth: '250px',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'pre-line',
                                        }}
                                    >
                                        {row.guide_note?.length > 200
                                            ? `${row.guide_note.substring(0, 200)}...`
                                            : row.guide_note}
                                    </TableCell>
                                </Tooltip>
                                <TableCell align="right" className="nowrap">
                                    <Link to={`/esgquestions/edit/${row.id}`}>
                                        <Tooltip title="Edit">
                                            <IconButton>
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Link>
                                    <IconButton
                                        onClick={() => {
                                            showModal(MODAL_TYPE.CONFIRMATION, {
                                                title: `Are you sure you want to delete this ESG Question?`,
                                                content:
                                                    'This action is irreversible and will remove connected ESGs as well.',
                                                confirm: () => deleteEsgQuestion(row.id),
                                            });
                                        }}
                                    >
                                        <Tooltip title="Delete">
                                            <DeleteIcon />
                                        </Tooltip>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))
                    )}
                </TableBody>
            </Table>
            <TablePagination
                count={total || 0}
                rowsPerPage={limit}
                component="div"
                page={page}
                onPageChange={(_, newPage) => handlePageChange(newPage, setParams, limit)}
                rowsPerPageOptions={TABLE_ROW_COUNT_OPTIONS}
                onRowsPerPageChange={(event) => handleChangeRowsPerPage(event, setParams)}
            />
        </>
    );
};

export default EsgQuestionsTable;
